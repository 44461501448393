<template>
  <b-modal
    id="modal_return_items"
    :title="modalTitle"
    no-close-on-backdrop
    hide-header-close
    size="xl"
    scrollable
    centered
    @hidden="hideModal"
  >
    <e-spinner v-if="localBusy" />
    <FormulateForm
      v-else
      ref="formReturnItems"
      name="formReturnItems"
    >
      <b-container>
        <b-card-actions
          v-if="showSaleInfo"
          :title="$t('Dados da venda')"
          no-actions
        >
          <b-row>
            <b-col md="3">
              <FormulateInput
                id="modal_return_items-delivery_status"
                v-model="localSaleData.deliveryStatus"
                type="label"
                :label="$t('Status Entrega')"
                :options="deliveryStatusOptions()"
                is-badge
              />
            </b-col>
            <b-col md="3">
              <FormulateInput
                id="modal_return_items-pending_payment_value"
                v-model="localSaleData.pendingPaymentValue"
                type="label"
                :label="$t('Pagamento pendente')"
                filter="currency"
              />
            </b-col>
          </b-row>
        </b-card-actions>

        <b-card-actions
          v-if="showProducts"
          :title="$t('Dados da devolução')"
          no-actions
        >
          <template v-if="showProductReturn">
            <b-row v-if="!isErp">
              <b-col md="4">
                <e-payment-method
                  id="modal_return_items-payment_method"
                  v-model="localForm.paymentMethod"
                  :required="true"
                  flag="payBoxReturn"
                />
              </b-col>
              <b-col md="8">
                <FormulateInput
                  id="modal_return_items-observation"
                  v-model="localForm.observation"
                  :label="$t('Observação')"
                  type="textarea"
                  :rows="2"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                v-if="isConsignedSaleFinish"
                md="12"
              >
                <p class="h4 mb-0 text-dark">
                  {{ $t('Valor máximo de devolução') }} <br>
                  <span class="h3 text-dark">
                    {{ localSaleData.consignedMaxReturnValue | currency }}
                  </span>
                </p>
              </b-col>
            </b-row>
          </template>
          <b-row v-else>
            <b-col>
              <b-alert
                show
                variant="warning"
                class="p-2"
              >
                <p>
                  {{ $t('SALE.UI.MODAL_RETURN.REQUIREMENT_MESSAGE') }}
                </p>
                <ul>
                  <li>{{ $t('SALE.UI.MODAL_RETURN.PAYMENT_REQUIREMENT') }}</li>
                  <li>
                    {{
                      $t('SALE.UI.MODAL_RETURN.DELIVERY_STATUS_REQUIREMENT', {
                        status: deliveryStatusLabel.Completed,
                      })
                    }}
                  </li>
                </ul>
              </b-alert>
            </b-col>
          </b-row>
        </b-card-actions>

        <b-card-actions
          v-if="showProductReturn"
          :title="$t('Produtos devolvidos')"
          no-actions
        >
          <b-table
            show-empty
            responsive
            striped
            class="bordered"
            :fields="fields"
            :items="localForm.returnedItems"
          >
            <template #cell(product)="row">
              <!-- TODO: When a Product Kit has a Lending, it is not showing the Product Kit name info -->
              <sup
                v-if="row.item.kitProduct"
                class="m-0 text-primary"
              >
                {{ row.item.kitProduct.name }}
              </sup>

              <p class="m-0">
                {{ row.item.skuProduct.name }}
              </p>
            </template>

            <template #cell(quantity)="row">
              <template v-if="row.item.returnDate">
                {{ row.item.quantity }}
              </template>
              <template v-else>
                <FormulateInput
                  :id="`modal_return_item-quantity_${row.index}`"
                  v-model.number="row.item.quantity"
                  name="Qtde."
                  type="number"
                  min="0"
                  :validation="[
                    ['min', 0],
                    ['max', row.item.maxQuantity],
                  ]"
                  @input="(val) => onInputQuantity(row.item, val)"
                />
              </template>
            </template>

            <template #cell(returnDate)="row">
              <p class="m-0">
                <span v-if="row.item.returnDate">
                  {{ row.item.returnDate | datetime }}
                </span>
                <span v-else> - </span>
              </p>
            </template>

            <template #cell(price)="row">
              {{ row.item.priceRuleSelected.priceInfo.price | currency }}
            </template>

            <template #cell(netValue)="row">
              {{ row.item.priceRuleSelected.priceInfo.localNetValue | currency }}
            </template>
          </b-table>
        </b-card-actions>

        <b-card-actions
          v-if="showReclaimItem"
          :title="$t('Retorno de Comodatos/Aluguéis')"
          no-actions
        >
          <b-table
            show-empty
            responsive
            striped
            class="bordered"
            :fields="lendingFields"
            :items="localForm.returnedLendingItems"
          >
            <template #head(returnQuantityDamaged)="data">
              <span class="text-danger">
                <e-instructions
                  :title="$t('Qtde. Avariada')"
                  :instruction="$t('São os produtos que o cliente deverá pagar devido a danos, perdas, etc.')
                  "
                />
                {{ data.label.toUpperCase() }}
              </span>
            </template>

            <template #cell(returnItem)="row">
              <div class="d-flex justify-content-center align-items-center">
                <template v-if="row.item.returnDate">
                  {{ row.item.returnDate | datetime }}
                </template>
                <FormulateInput
                  v-else
                  :id="`modal_return_item-lending_return_check_${row.index}`"
                  v-model="row.item.willReturn"
                  type="e-checkbox"
                />
              </div>
            </template>

            <template #cell(returnDateLimit)="row">
              {{ row.item.returnDateLimit | datetime }}
            </template>

            <template #cell(deliveredDate)="row">
              <span v-if="row.item.deliveredDate">
                {{ row.item.deliveredDate | datetime }}
              </span>
              <span v-else-if="localSaleData && localSaleData.completedDate">
                {{ localSaleData.completedDate | datetime }}
              </span>
              <span v-else> - </span>
            </template>

            <template #cell(contractualFine)="row">
              {{ row.item.skuProduct.contractualFine | currency }}
            </template>
            <template #cell(product)="row">
              <sup
                v-if="row.item.skuProductAssociated"
                class="m-0 text-primary"
              >
                {{ row.item.skuProductAssociated.name }}
              </sup>

              <p class="m-0">
                {{ row.item.skuProduct.name }}
              </p>
            </template>

            <template #cell(quantity)="row">
              <template v-if="row.item.returnDate">
                {{ row.item.maxQuantity - row.item.returnQuantityDamaged }}
              </template>
              <FormulateInput
                v-else
                :id="`modal_return_item-lending_quantity_${row.index}`"
                v-model.number="row.item.quantity"
                name="Qtde."
                type="number"
                min="0"
                :validation="[
                  ['min', 0],
                  ['max', row.item.maxQuantity - row.item.returnQuantityDamaged],
                ]"
                :validation-messages="{
                  max: `${$t('Deve ser menor que')} ${row.item.maxQuantity - row.item.returnQuantityDamaged}`,
                }"
                :disabled="!row.item.willReturn"
                @input="(val) => onInputQuantity(row.item, val)"
              />
            </template>

            <template #cell(returnQuantityDamaged)="row">
              <template v-if="row.item.returnDate">
                {{ row.item.returnQuantityDamaged }}
              </template>
              <FormulateInput
                v-else
                :id="`modal_return_item-lending_damaged_quantity_${row.index}`"
                v-model.number="row.item.returnQuantityDamaged"
                type="number"
                min="0"
                :validation="[
                  ['min', 0],
                  ['max', row.item.maxQuantity - row.item.quantity],
                ]"
                :validation-messages="{
                  max: `${$t('Deve ser menor que')} ${row.item.maxQuantity - row.item.quantity}`,
                }"
                :disabled="!row.item.willReturn"
                @input="(val) => onInputQuantity(row.item, val)"
              />
            </template>
          </b-table>
        </b-card-actions>
      </b-container>
    </FormulateForm>

    <template #modal-footer>
      <b-row class="w-100 d-flex justify-content-between align-items-center">
        <b-col v-if="showProductReturn">
          <div class="d-flex align-items-center">
            <p class="h2 mb-0 text-dark">
              {{ $t('Total a devolver') }}
              <span class="h1 text-dark"> {{ localForm.value | currency }} </span>
            </p>
          </div>
        </b-col>

        <b-col class="d-flex justify-content-end">
          <e-button
            id="modal_return_items-btn_cancel"
            class="mr-1"
            variant="outline-primary"
            :text="$t('Voltar')"
            :text-shortcuts="['ESC']"
            @click="hideModal"
          />

          <e-button
            id="modal_return_items-btn_confirm"
            variant="primary"
            :text="$t('Confirmar')"
            :busy="busy || localBusy"
            :disabled="!canConfirmReturns"
            @click="onConfirm"
          />
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import { BModal, BContainer, BRow, BCol, BTable, BAlert } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { payBoxSale, productDomains, saleItemReturnedUtils, saleItemUtils, statusTypes } from '@/mixins'
import EButton from '@/views/components/EButton.vue'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import { roundDecimal } from '@/utils/number-utils'
import EInstructions from '@/views/components/EInstructions.vue'
import ESpinner from '@/views/components/ESpinner.vue'
import EPaymentMethod from '@/views/components/inputs/EPaymentMethod.vue'
import moment from 'moment'
import { getInitialConsignReturnForm } from '@/mixins/dominio/pay-box-sale'

const getInitialSaleData = () => ({
  id: null,
  netValue: 0,
  grossValue: 0,
  returnSale: {
    value: 0,
  },
  consigned: false,
  deliveryStatus: null,
  consignedMaxReturnValue: 0,
  pendingPaymentValue: 0,
})

export default {
  components: {
    BModal,
    BContainer,
    BRow,
    BCol,
    EButton,
    BCardActions,
    BTable,
    BAlert,
    EInstructions,
    ESpinner,
    EPaymentMethod,
  },

  mixins: [productDomains, statusTypes],

  props: {
    // deve ter os mesmos dados de getInitialSaleData (está aqui no componente)
    saleData: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    returnForm: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    showReclaim: {
      type: Boolean,
      default: false,
    },
    showProducts: {
      type: Boolean,
      default: false,
    },
    showSaleInfo: {
      type: Boolean,
      default: false,
    },

    isErp: {
      type: Boolean,
      default: false,
    },

    busy: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      localForm: getInitialConsignReturnForm(),
      localSaleData: getInitialSaleData(),
      loadingAddressForm: false,
      localBusy: false,
      consignedLimitValue: 0,
    }
  },

  computed: {
    ...mapGetters('pages/pdv', ['getPaymentMethodsInPdvOptions']),
    ...mapGetters('pages/pdv/payBox', {
      stPDVIsConsignedSaleFinish: 'isConsignedSaleFinish',
    }),

    isConsignedSaleFinish() {
      if (this.localSaleData.id === null) {
        return this.stPDVIsConsignedSaleFinish
      }

      const hasPendingPayment = this.localSaleData.pendingPaymentValue > 0
      return (
        this.localSaleData?.consigned &&
        this.localSaleData?.deliveryStatus ===
        statusTypes.computed.deliveryStatusEnum().COMPLETED &&
        !hasPendingPayment
      )
    },

    showProductReturn() {
      return this.isConsignedSaleFinish && this.showProducts
    },

    showReclaimItem() {
      return this.showReclaim && this.localForm.returnedLendingItems.length > 0
    },

    canConfirmReturns() {
      if (this.isConsignedSaleFinish) return true

      return this.localForm.returnedLendingItems.some(rli => rli.willReturn)
    },

    fields() {
      return [
        {
          label: this.$t('Retorno'),
          key: 'returnDate',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Nome do Produto'),
          key: 'product',
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: this.$t('Preço un.'),
          key: 'price',
          thClass: 'text-center',
          tdClass: 'text-left',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Total'),
          key: 'netValue', // priceInfo.netValue
          thClass: 'text-center',
          tdClass: 'text-left',
          thStyle: { width: '110px' },
        },
        {
          label: this.$t('Qtde. Vendida'),
          key: 'maxQuantity', // maxQuantity
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Qtde. Devolvida'),
          key: 'quantity', // quantity
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
        },
      ]
    },

    lendingFields() {
      return [
        {
          label: this.$t('Retorno'),
          key: 'returnItem',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Venda'),
          key: 'saleId',
          thClass: 'text-center',
          tdClass: 'text-center',
          formatter: () => this.localSaleData?.id || '-',
        },
        {
          label: this.$t('Nome'),
          key: 'product',
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: this.$t('Data limite de retorno'),
          key: 'returnDateLimit',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '140px' },
        },
        {
          label: this.$t('Data Lanç.'),
          key: 'createdDate',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '140px' },
          formatter: val => (val ? this.$options.filters.datetime(val) : '-'),
        },
        {
          label: this.$t('Data da entrega'),
          key: 'deliveredDate',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '140px' },
        },
        {
          label: this.$t('Dias de atraso'),
          key: 'daysLate',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '140px' },
          formatter: (val, index, item) => this.getDaysLate(item),
        },
        {
          label: this.$t('Cliente'),
          key: 'customer',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '140px' },
          formatter: () =>
            `${this.localSaleData?.customer?.id} - ${this.localSaleData?.customer?.name || this.localSaleData?.customer?.companyName
            }`,
        },
        {
          label: this.$t('Valor unitário indenização'),
          key: 'contractualFine',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '160px' },
        },
        {
          label: this.$t('Qtde. Vendida'),
          key: 'maxQuantity',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Qtde. Retornada'),
          key: 'quantity',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Qtde. Avariada'),
          key: 'returnQuantityDamaged',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
        },
      ]
    },

    modalTitle() {
      const title = this.showReclaim
        ? this.$t('Produtos a serem retornados')
        : this.$t('Devoluções')
      if (this.localSaleData.id) return `${title} (Venda #${this.localSaleData.id})`
      return title
    },
  },

  methods: {
    hideModal() {
      this.resetModal()
      this.$bvModal.hide('modal_return_items')
    },
    async showModalReturn(saleId) {
      this.$bvModal.show('modal_return_items')
      let saleData = getInitialSaleData()
      if (saleId) {
        try {
          this.localBusy = true
          const { data } = await this.$http.get(`/api/sales/pay-box-sale/${saleId}`)
          saleData = data

          const itemsFormatted = saleItemUtils.buildSaleItemsAndKitsFromApi(data.items, data.itemKits, data.itemsReturned)

          const itemsReturnedFormatted = payBoxSale.methods.prepareSaleReturnedData(
            itemsFormatted,
            data.itemsReturned
          )

          this.localForm = {
            ...getInitialConsignReturnForm(),
            ...itemsReturnedFormatted,
          }
        } catch (error) {
          this.showError({ error })
        } finally {
          this.localBusy = false
        }
      } else {
        saleData = this.saleData
        const localReturnedItems = this.returnForm.returnedItems.map(i => ({ ...i }))
        const localLendingReturnedItems = this.returnForm.returnedLendingItems.map(i => ({
          ...i,
        }))

        this.localForm = {
          ...this.returnForm,
          returnedItems: localReturnedItems,
          returnedLendingItems: localLendingReturnedItems,
        }
      }

      this.localSaleData = {
        ...getInitialSaleData(),
        id: saleData.id,
        netValue: saleData.netValue,
        grossValue: saleData.grossValue,
        returnSale: saleData.returnSale,
        consigned: saleData.consigned,
        deliveryStatus: saleData.deliveryStatus,
        consignedMaxReturnValue: saleData.consignedMaxReturnValue,
        completedDate: saleData.completedDate,
        customer: saleData.customer,
        pendingPaymentValue: saleData.pendingPaymentValue,
      }

      this.updateLocalFormValue()
    },

    getDaysLate(item) {
      const maxReturnDate = moment(item.returnDateLimit)
      const dateReturned = item.returnDate ? moment(item.returnDate) : moment()
      const diffDays = dateReturned.diff(maxReturnDate, 'days')
      return diffDays > 0 ? diffDays : 0
    },

    async onConfirm() {
      this.$refs.formReturnItems.showErrors()
      if (!this.isValidInputs()) return

      if (this.isErp) {
        const isFirstReturn = !this.localForm.returnedItems.some(i => i.returnDate)
        if (isFirstReturn) {
          const confirm = await this.confirm({
            title: this.$t('Ao confirmar, depois não poderá mais editar o pedido.'),
            text: this.$t('Confirma esta ação?'),
          })

          if (!confirm) return
        }
      }

      const reclaimItems = [...this.localForm.returnedLendingItems]

      if (reclaimItems.some(i => i.willReturn)) {
        const hasReclaimsDamaged = reclaimItems.some(i => i.willReturn && i.returnQuantityDamaged > 0)

        if (hasReclaimsDamaged) {
          const confirmedOverdue = await this.confirm({
            title: this.$t('Há retornos avariados'),
            text: this.$t(
              'Será gerada uma nova venda com o valor do produto danificado. Ela estará disponível em "vendas pendentes".'
            ),
          })
          if (!confirmedOverdue) return
        }
      }

      // usado emit para que possa ser utilizado ou não diferentes endpoints ao confirmar os retornos.
      this.localForm.saleId = this.localSaleData.id
      this.$emit('confirm', this.localForm)
    },

    resetModal() {
      this.localForm = getInitialConsignReturnForm()
      this.localSaleData = getInitialSaleData()
    },

    onInputQuantity(item, value) {
      const itemUpdated = saleItemReturnedUtils.updateSaleItemReturned(item, {
        quantity: value
      })

      // eslint-disable-next-line no-param-reassign
      item.priceRuleSelected = itemUpdated.priceRuleSelected

      this.updateLocalFormValue()
    },

    updateLocalFormValue() {
      this.localForm.value = roundDecimal(
        this.localForm.returnedItems.reduce((total, i) => total + i.priceRuleSelected.priceInfo.localNetValue, 0),
        4
      )
    },

    isValidInputs() {
      if (this.showProductReturn) {
        if (!this.isErp && this.localForm.paymentMethod === null) {
          this.showInvalidDataMessage({ message: this.$t('Em dados da devolução') })
          return false
        }

        if (this.localForm.returnedItems.some(i => i.quantity > i.maxQuantity || i.quantity < 0)) {
          this.showInvalidDataMessage({
            message: this.$t('Em produtos retornados'),
          })
          return false
        }
      }

      if (this.showReclaim) {
        const hasLendingOverflowDamagedAmount = this.localForm.returnedLendingItems.some(
          i => i.willReturn && i.returnQuantityDamaged > i.maxQuantity - i.quantity
        )
        if (hasLendingOverflowDamagedAmount) {
          this.showInvalidDataMessage({
            message: this.$t('Em comodatos retornados'),
          })
          return false
        }

        if (
          this.localForm.returnedLendingItems.some(
            i => i.willReturn && i.returnQuantityDamaged === 0 && i.quantity === 0
          )
        ) {
          this.showInvalidDataMessage({
            message: this.$t(
              'Alguns comodatos/aluguéis para retorno estão com a quantidade zerada'
            ),
          })
          return false
        }
      }

      return true
    },
  },
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: 0.2s;
}

/* .fade-leave-active below version 2.1.8 */
.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}

.input-size {
  font-size: 1.4rem;
}
</style>
