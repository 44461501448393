var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal_return_items",
        title: _vm.modalTitle,
        "no-close-on-backdrop": "",
        "hide-header-close": "",
        size: "xl",
        scrollable: "",
        centered: "",
      },
      on: { hidden: _vm.hideModal },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "b-row",
                {
                  staticClass:
                    "w-100 d-flex justify-content-between align-items-center",
                },
                [
                  _vm.showProductReturn
                    ? _c("b-col", [
                        _c(
                          "div",
                          { staticClass: "d-flex align-items-center" },
                          [
                            _c("p", { staticClass: "h2 mb-0 text-dark" }, [
                              _vm._v(
                                " " + _vm._s(_vm.$t("Total a devolver")) + " "
                              ),
                              _c("span", { staticClass: "h1 text-dark" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("currency")(_vm.localForm.value)
                                    ) +
                                    " "
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "b-col",
                    { staticClass: "d-flex justify-content-end" },
                    [
                      _c("e-button", {
                        staticClass: "mr-1",
                        attrs: {
                          id: "modal_return_items-btn_cancel",
                          variant: "outline-primary",
                          text: _vm.$t("Voltar"),
                          "text-shortcuts": ["ESC"],
                        },
                        on: { click: _vm.hideModal },
                      }),
                      _c("e-button", {
                        attrs: {
                          id: "modal_return_items-btn_confirm",
                          variant: "primary",
                          text: _vm.$t("Confirmar"),
                          busy: _vm.busy || _vm.localBusy,
                          disabled: !_vm.canConfirmReturns,
                        },
                        on: { click: _vm.onConfirm },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm.localBusy
        ? _c("e-spinner")
        : _c(
            "FormulateForm",
            { ref: "formReturnItems", attrs: { name: "formReturnItems" } },
            [
              _c(
                "b-container",
                [
                  _vm.showSaleInfo
                    ? _c(
                        "b-card-actions",
                        {
                          attrs: {
                            title: _vm.$t("Dados da venda"),
                            "no-actions": "",
                          },
                        },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "3" } },
                                [
                                  _c("FormulateInput", {
                                    attrs: {
                                      id: "modal_return_items-delivery_status",
                                      type: "label",
                                      label: _vm.$t("Status Entrega"),
                                      options: _vm.deliveryStatusOptions(),
                                      "is-badge": "",
                                    },
                                    model: {
                                      value: _vm.localSaleData.deliveryStatus,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.localSaleData,
                                          "deliveryStatus",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "localSaleData.deliveryStatus",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "3" } },
                                [
                                  _c("FormulateInput", {
                                    attrs: {
                                      id: "modal_return_items-pending_payment_value",
                                      type: "label",
                                      label: _vm.$t("Pagamento pendente"),
                                      filter: "currency",
                                    },
                                    model: {
                                      value:
                                        _vm.localSaleData.pendingPaymentValue,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.localSaleData,
                                          "pendingPaymentValue",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "localSaleData.pendingPaymentValue",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.showProducts
                    ? _c(
                        "b-card-actions",
                        {
                          attrs: {
                            title: _vm.$t("Dados da devolução"),
                            "no-actions": "",
                          },
                        },
                        [
                          _vm.showProductReturn
                            ? [
                                !_vm.isErp
                                  ? _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          { attrs: { md: "4" } },
                                          [
                                            _c("e-payment-method", {
                                              attrs: {
                                                id: "modal_return_items-payment_method",
                                                required: true,
                                                flag: "payBoxReturn",
                                              },
                                              model: {
                                                value:
                                                  _vm.localForm.paymentMethod,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.localForm,
                                                    "paymentMethod",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "localForm.paymentMethod",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-col",
                                          { attrs: { md: "8" } },
                                          [
                                            _c("FormulateInput", {
                                              attrs: {
                                                id: "modal_return_items-observation",
                                                label: _vm.$t("Observação"),
                                                type: "textarea",
                                                rows: 2,
                                              },
                                              model: {
                                                value:
                                                  _vm.localForm.observation,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.localForm,
                                                    "observation",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "localForm.observation",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "b-row",
                                  [
                                    _vm.isConsignedSaleFinish
                                      ? _c("b-col", { attrs: { md: "12" } }, [
                                          _c(
                                            "p",
                                            {
                                              staticClass: "h4 mb-0 text-dark",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "Valor máximo de devolução"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c("br"),
                                              _c(
                                                "span",
                                                { staticClass: "h3 text-dark" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("currency")(
                                                          _vm.localSaleData
                                                            .consignedMaxReturnValue
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            : _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    [
                                      _c(
                                        "b-alert",
                                        {
                                          staticClass: "p-2",
                                          attrs: {
                                            show: "",
                                            variant: "warning",
                                          },
                                        },
                                        [
                                          _c("p", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "SALE.UI.MODAL_RETURN.REQUIREMENT_MESSAGE"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _c("ul", [
                                            _c("li", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "SALE.UI.MODAL_RETURN.PAYMENT_REQUIREMENT"
                                                  )
                                                )
                                              ),
                                            ]),
                                            _c("li", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "SALE.UI.MODAL_RETURN.DELIVERY_STATUS_REQUIREMENT",
                                                      {
                                                        status:
                                                          _vm
                                                            .deliveryStatusLabel
                                                            .Completed,
                                                      }
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.showProductReturn
                    ? _c(
                        "b-card-actions",
                        {
                          attrs: {
                            title: _vm.$t("Produtos devolvidos"),
                            "no-actions": "",
                          },
                        },
                        [
                          _c("b-table", {
                            staticClass: "bordered",
                            attrs: {
                              "show-empty": "",
                              responsive: "",
                              striped: "",
                              fields: _vm.fields,
                              items: _vm.localForm.returnedItems,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "cell(product)",
                                  fn: function (row) {
                                    return [
                                      row.item.kitProduct
                                        ? _c(
                                            "sup",
                                            { staticClass: "m-0 text-primary" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    row.item.kitProduct.name
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c("p", { staticClass: "m-0" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(row.item.skuProduct.name) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                                {
                                  key: "cell(quantity)",
                                  fn: function (row) {
                                    return [
                                      row.item.returnDate
                                        ? [
                                            _vm._v(
                                              " " +
                                                _vm._s(row.item.quantity) +
                                                " "
                                            ),
                                          ]
                                        : [
                                            _c("FormulateInput", {
                                              attrs: {
                                                id:
                                                  "modal_return_item-quantity_" +
                                                  row.index,
                                                name: "Qtde.",
                                                type: "number",
                                                min: "0",
                                                validation: [
                                                  ["min", 0],
                                                  ["max", row.item.maxQuantity],
                                                ],
                                              },
                                              on: {
                                                input: function (val) {
                                                  return _vm.onInputQuantity(
                                                    row.item,
                                                    val
                                                  )
                                                },
                                              },
                                              model: {
                                                value: row.item.quantity,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    row.item,
                                                    "quantity",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression: "row.item.quantity",
                                              },
                                            }),
                                          ],
                                    ]
                                  },
                                },
                                {
                                  key: "cell(returnDate)",
                                  fn: function (row) {
                                    return [
                                      _c("p", { staticClass: "m-0" }, [
                                        row.item.returnDate
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("datetime")(
                                                      row.item.returnDate
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ])
                                          : _c("span", [_vm._v(" - ")]),
                                      ]),
                                    ]
                                  },
                                },
                                {
                                  key: "cell(price)",
                                  fn: function (row) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("currency")(
                                              row.item.priceRuleSelected
                                                .priceInfo.price
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "cell(netValue)",
                                  fn: function (row) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("currency")(
                                              row.item.priceRuleSelected
                                                .priceInfo.localNetValue
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              663687336
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.showReclaimItem
                    ? _c(
                        "b-card-actions",
                        {
                          attrs: {
                            title: _vm.$t("Retorno de Comodatos/Aluguéis"),
                            "no-actions": "",
                          },
                        },
                        [
                          _c("b-table", {
                            staticClass: "bordered",
                            attrs: {
                              "show-empty": "",
                              responsive: "",
                              striped: "",
                              fields: _vm.lendingFields,
                              items: _vm.localForm.returnedLendingItems,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "head(returnQuantityDamaged)",
                                  fn: function (data) {
                                    return [
                                      _c(
                                        "span",
                                        { staticClass: "text-danger" },
                                        [
                                          _c("e-instructions", {
                                            attrs: {
                                              title: _vm.$t("Qtde. Avariada"),
                                              instruction: _vm.$t(
                                                "São os produtos que o cliente deverá pagar devido a danos, perdas, etc."
                                              ),
                                            },
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(data.label.toUpperCase()) +
                                              " "
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "cell(returnItem)",
                                  fn: function (row) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-content-center align-items-center",
                                        },
                                        [
                                          row.item.returnDate
                                            ? [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("datetime")(
                                                        row.item.returnDate
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            : _c("FormulateInput", {
                                                attrs: {
                                                  id:
                                                    "modal_return_item-lending_return_check_" +
                                                    row.index,
                                                  type: "e-checkbox",
                                                },
                                                model: {
                                                  value: row.item.willReturn,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      row.item,
                                                      "willReturn",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "row.item.willReturn",
                                                },
                                              }),
                                        ],
                                        2
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "cell(returnDateLimit)",
                                  fn: function (row) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("datetime")(
                                              row.item.returnDateLimit
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "cell(deliveredDate)",
                                  fn: function (row) {
                                    return [
                                      row.item.deliveredDate
                                        ? _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("datetime")(
                                                    row.item.deliveredDate
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ])
                                        : _vm.localSaleData &&
                                          _vm.localSaleData.completedDate
                                        ? _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("datetime")(
                                                    _vm.localSaleData
                                                      .completedDate
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ])
                                        : _c("span", [_vm._v(" - ")]),
                                    ]
                                  },
                                },
                                {
                                  key: "cell(contractualFine)",
                                  fn: function (row) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("currency")(
                                              row.item.skuProduct
                                                .contractualFine
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "cell(product)",
                                  fn: function (row) {
                                    return [
                                      row.item.skuProductAssociated
                                        ? _c(
                                            "sup",
                                            { staticClass: "m-0 text-primary" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    row.item
                                                      .skuProductAssociated.name
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c("p", { staticClass: "m-0" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(row.item.skuProduct.name) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                                {
                                  key: "cell(quantity)",
                                  fn: function (row) {
                                    return [
                                      row.item.returnDate
                                        ? [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  row.item.maxQuantity -
                                                    row.item
                                                      .returnQuantityDamaged
                                                ) +
                                                " "
                                            ),
                                          ]
                                        : _c("FormulateInput", {
                                            attrs: {
                                              id:
                                                "modal_return_item-lending_quantity_" +
                                                row.index,
                                              name: "Qtde.",
                                              type: "number",
                                              min: "0",
                                              validation: [
                                                ["min", 0],
                                                [
                                                  "max",
                                                  row.item.maxQuantity -
                                                    row.item
                                                      .returnQuantityDamaged,
                                                ],
                                              ],
                                              "validation-messages": {
                                                max:
                                                  _vm.$t("Deve ser menor que") +
                                                  " " +
                                                  (row.item.maxQuantity -
                                                    row.item
                                                      .returnQuantityDamaged),
                                              },
                                              disabled: !row.item.willReturn,
                                            },
                                            on: {
                                              input: function (val) {
                                                return _vm.onInputQuantity(
                                                  row.item,
                                                  val
                                                )
                                              },
                                            },
                                            model: {
                                              value: row.item.quantity,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  row.item,
                                                  "quantity",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression: "row.item.quantity",
                                            },
                                          }),
                                    ]
                                  },
                                },
                                {
                                  key: "cell(returnQuantityDamaged)",
                                  fn: function (row) {
                                    return [
                                      row.item.returnDate
                                        ? [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  row.item.returnQuantityDamaged
                                                ) +
                                                " "
                                            ),
                                          ]
                                        : _c("FormulateInput", {
                                            attrs: {
                                              id:
                                                "modal_return_item-lending_damaged_quantity_" +
                                                row.index,
                                              type: "number",
                                              min: "0",
                                              validation: [
                                                ["min", 0],
                                                [
                                                  "max",
                                                  row.item.maxQuantity -
                                                    row.item.quantity,
                                                ],
                                              ],
                                              "validation-messages": {
                                                max:
                                                  _vm.$t("Deve ser menor que") +
                                                  " " +
                                                  (row.item.maxQuantity -
                                                    row.item.quantity),
                                              },
                                              disabled: !row.item.willReturn,
                                            },
                                            on: {
                                              input: function (val) {
                                                return _vm.onInputQuantity(
                                                  row.item,
                                                  val
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                row.item.returnQuantityDamaged,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  row.item,
                                                  "returnQuantityDamaged",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "row.item.returnQuantityDamaged",
                                            },
                                          }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3589914788
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }